import { MDCTopAppBar } from '@material/top-app-bar';
//import { TabBarSetController } from './MDC-Tabs';
import { AppDrawer } from './AppDrawer';




export enum HeaderStates { large = 'large', small = 'small' }




export class AppBar {
    private _MDC: MDCTopAppBar;
 //   private HeaderTabs: TabBarSetController<HTMLElement>;
  private TitleImage: HTMLImageElement | null;
  private ContentAdjust: HTMLElement | null;
  private TopAppBar: HTMLElement | null;
    private PageTitle: HTMLElement | null;
    private Sections?: NodeListOf<HTMLDivElement>;
    private Header: HTMLElement;
  private Breadcrumb: HTMLElement | null;
    private SiteSearch: SiteSearch<HTMLElement>;
    

    constructor(private root: HTMLElement,
        private Drawer: AppDrawer
    ) {

        this._MDC = MDCTopAppBar.attachTo(root);
      //  this.HeaderTabs = new TabBarSetController<HTMLElement>(root);


        this.Header = root; //document.querySelector<HTMLElement>('header');
        this.PageTitle = root.querySelector<HTMLElement>('h1');
        this.TitleImage = root.querySelector<HTMLImageElement>('.logo img');
        this.ContentAdjust = root.querySelector<HTMLElement>('.mdc-top-app-bar--fixed-adjust');
        this.TopAppBar = root.querySelector<HTMLElement>('.mdc-top-app-bar');
        this.Breadcrumb = root.querySelector<HTMLElement>('.Breadcrumb');

        //this.Sections = document.querySelectorAll<HTMLDivElement>('.section__block');
        

        if (Drawer) {
            this._MDC.listen('MDCTopAppBar:nav', () => {
                Drawer.Open = !Drawer.Open;
            });
        }


        this.SiteSearch = new SiteSearch(this, root.querySelectorAll<HTMLElement>('button[name="ToggleSearch"]'));



        if (this.Header.classList.contains("header-size__fixed")) {
            this.UseFixedHeightLogo = true;
            //this.AllowHeaderHide = false;
        }

       // this.HandleDimentionChange();
    }


    public Dispose() {
        this._MDC.destroy();
     //   this.HeaderTabs.Dispose();

        clearInterval(this.Timer);
        //if (this.Timer) this.Timer.unref();
    }

    private DeBounce: number = 5;

    private CurrentHeaderState = true;
    private LastScrollPos = 0;
   // private LogoHeight = 128;
    private Scrolling: boolean = false;
    private Resizing: boolean = false;
    private UseFixedHeightLogo: boolean = false;
    private HideToolbarOnScroll: boolean = true;
    private FadeOutOnScroll: boolean = false;
    private CurrentHeaderSize: HeaderStates = HeaderStates.large;
    private Timer: any; // Interval Handler

    public AttachScroll() {

        if (window) {
            window.addEventListener('resize', (event: UIEvent) => {
                this.Resizing = true;
            }, { capture: false, passive: true });

            window.addEventListener('scroll', (event: Event) => {
                this.Scrolling = true;
            }, { capture: false, passive: true });
        }

        var Owner = this;

        Owner.Resizing = true;

        this.Timer = setInterval(() => {
            if (Owner.Scrolling || Owner.Resizing) {

                if (Owner.DeBounce > 0) {
                    Owner.DeBounce--;
                    Owner.HandleDimentionChange(true);
                }
                else {
                    Owner.DeBounce = 5;
                    Owner.Scrolling = false;
                    Owner.Resizing = false;
                }
            }
        }, 100);

//        this.HandleDimentionChange(true);
    }





    




    private LastScrollValue : number = 0;



  private HandleDimentionChange(HeightChanged: boolean) {

    //console.log('HandleDimentionChange');

    var HeaderState = this.CurrentHeaderState;
    var YOffset = window.pageYOffset;
    //    var HeightChanged : boolean = false;

    //   console.log('a', this.LastScrollPos)

    HeaderState = (YOffset < 550); //  threshold

    if (Math.abs(this.LastScrollValue - YOffset) > 150 &&
      YOffset <= this.LastScrollPos) HeaderState = true; // scroll up show

    //     console.log('Scroll up', (YOffset <= this.LastScrollPos), YOffset, this.LastScrollPos, HeaderState);


    if (YOffset > this.LastScrollPos) {
      this.LastScrollValue = YOffset;
    }
    this.LastScrollPos = YOffset;






    var NewHeaderSize: HeaderStates = this.CurrentHeaderSize;;
    var h = '3';



    var AllowHeaderHide = (document.body.clientHeight - window.innerHeight - 200) > 0;

    if (!AllowHeaderHide && this.root.style.transform != 'none') {
      this.root.style.transform = 'none';
      this.CurrentHeaderState = HeaderState = true;
    }





    if (window.innerWidth < 800) { //|| (document.body.clientHeight - window.innerHeight < 128)
      NewHeaderSize = HeaderStates.small;

      if (window.innerWidth > 1200) { // wide but not very wide see height calc above.
        h = '2';
      }
    } else {

      //if (!AllowHeaderHide || this.UseFixedHeightLogo) {
      //    NewHeaderSize = HeaderStates.small;
      //} else {

      NewHeaderSize = ((this.LastScrollPos < 100 || (this.LastScrollPos < 200 && NewHeaderSize == HeaderStates.large))
        ? HeaderStates.large
        : HeaderStates.small
      );

      //CalcHeight = Math.max(128 - this.LastScrollPos, 64);
      // }
    }

    if (this.PageTitle) this.PageTitle.style.fontSize = h + 'vw';


    //   console.log(YOffset, this.LastScrollPos, NewHeaderSize);


    if (!this.UseFixedHeightLogo) {

      if (AllowHeaderHide && this.FadeOutOnScroll && this.Header != null) {
        var opacHeght = Math.max(this.LastScrollPos - 20, 0);

        var Opp = Math.max(1 - ((opacHeght / 200)), 0);

        this.Header.style.opacity = Opp.toString();
        if (Opp < 1) {
          this.root.classList.add('elevated');
        } else {
          this.root.classList.remove('elevated');
        }
      }


      if (!this.Header.classList.contains('header-size')) this.Header.classList.add('header-size');

      if (this.CurrentHeaderSize != NewHeaderSize) {
        this.Header.classList.remove('header-size__' + this.CurrentHeaderSize);
        this.Header.classList.add('header-size__' + NewHeaderSize);
        this.CurrentHeaderSize = NewHeaderSize;
      }


      if (AllowHeaderHide && this.HideToolbarOnScroll) {
        if (HeaderState) {
          this.root.style.transform = 'none';
        } else {
          this.root.style.transform = 'translate3d(0px, -150%, 0px)';
        }
        this.CurrentHeaderState = HeaderState;
      }

    }


    if (this.Breadcrumb != null) {
      if (AllowHeaderHide) {
        if (this.CurrentHeaderSize == HeaderStates.large) {
          this.Breadcrumb.classList.remove('out')
          this.Breadcrumb.classList.add('in');
        }
        else {
          this.Breadcrumb.classList.remove('in')
          this.Breadcrumb.classList.add('out');
        }
      }
    }



    if ((this.Resizing || HeightChanged) && this.ContentAdjust != null) {
      var Header = this.Header;
      var ContentAdjust = this.ContentAdjust;

      setTimeout(function () {
        var HeaderRect: ClientRect = Header.getBoundingClientRect(); //.top;

        ContentAdjust.style.marginTop = HeaderRect.height.toString() + 'px';; //CalcHeight.toString() + 'px';
      }, 10);
    }




    if (this.Sections) {
      this.Sections.forEach(s => {
        var elemRect = s.getBoundingClientRect();
        if (elemRect.top < (window.innerHeight - 100)) {
          console.log("Change point H.1");
          if (s.classList.contains("hide")) s.classList.remove('hide');
        }
        else {
          console.log("Change point H.2");

          if (!s.classList.contains("hide")) s.classList.add('hide');
        }

      });
    }

  }


    public setScrollTarget(target: HTMLElement) {
        if (target) this._MDC.setScrollTarget(target);
    }


    public HideNav(isHide: boolean) {
        var NavBar = this.root.querySelector<HTMLElement>(".mdc-tab-bar");
        var TitleBlock = this.root.querySelector<HTMLElement>(".mdc-top-app-bar__title");

      if (NavBar && isHide) {
            NavBar.classList.add('hide');
        if (TitleBlock && window.innerWidth < 800) {
                TitleBlock.classList.add('hide');
            }
        }
      else {
        if (NavBar) NavBar.classList.remove('hide-init');
        if (NavBar) NavBar.classList.remove('hide');
        if (TitleBlock) TitleBlock.classList.remove('hide');
        }
    }
}



export class SiteSearch<T extends HTMLElement> {
  private _IsShown: boolean = false;
  private SearchBar: HTMLElement | null;

  constructor(private bar: AppBar, private root: NodeListOf<T>) {
    root.forEach(T => {
      T.onclick = () => this.ToggleSearch();
    });

    this.SearchBar = document.querySelector<HTMLElement>('.SearchBar');
  }


  private ToggleSearch() {
    if (this.SearchBar) {
      this._IsShown = !this.SearchBar.classList.contains("hide");

      if (this._IsShown) {
        this.SearchBar.classList.add('hide');
        this.bar.HideNav(false);
      } else {
        this.bar.HideNav(true);
        this.SearchBar.classList.remove('hide-init');
        this.SearchBar.classList.remove('hide');
      }
    }
  }

}
