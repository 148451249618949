



/*
   0		The request is not initialized.
   1		The request has been set up.
   2		The request has been sent.
   3		The request is in process.
   4		The request is completed.

*/






 export class Http<T>
 {

    constructor(public Url : string) {

    }

    public post(Data : any) : Promise<T> {

      return new Promise<T>((resolve, reject) => {
        if (window.XMLHttpRequest) {
            const xmlHTTP = new XMLHttpRequest();
            
            xmlHTTP.onreadystatechange = function () {
                if(xmlHTTP.readyState === XMLHttpRequest.DONE) {
                    var status = xmlHTTP.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                      // The request has been completed successfully
                        resolve(xmlHTTP.response);

                    } else {
                      // Oh no! There has been an error with the request!
                       reject(xmlHTTP.responseText);
                    }
                  }
            };
            xmlHTTP.open("POST", this.Url, true);
            xmlHTTP.setRequestHeader('X-Requested-With', 'XMLHttpRequest'); 
            xmlHTTP.responseType = 'json';
            xmlHTTP.send(Data);
            
        }
        });
    }


}







