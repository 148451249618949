import { AppBar } from './AppBar';
//import { TabBarSetController } from './MDC-Tabs';









export class AppMain {
  //private Tabs: TabBarSetController<HTMLElement>[] = new Array<TabBarSetController<HTMLElement>>();

  constructor(private root: HTMLElement, private Bar: AppBar) {
        if (Bar && root) {
            Bar.setScrollTarget(root);
        }

        if (document) {
            document.body.addEventListener('MDCDrawer:closed', () => {
              var a = document.querySelector<HTMLElement>('input, button');
              if (a) a.focus();
            });
        }

   

        //document.querySelectorAll<HTMLElement>('.mdc-tab-bar').forEach(el => {
        //   this.Tabs.push(new TabBarSetController<HTMLElement>(el));
        //});

        if (this.Bar) {
            this.Bar.AttachScroll();
        }
    }

  public Dispose() {
    if (this.Bar) {
      this.Bar.Dispose();
    }
  //  if (this.Tabs) this.Tabs.forEach(o => o.Dispose);

    //this.Tabs.Dispose();
  }


}
