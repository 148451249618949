


import { DropDownLink } from './components/DropDownMenu';
import { PaginationController } from './components/PaginationController';
import { AppBar } from './components/AppBar';
import { AppDrawer } from './components/AppDrawer';
import { AppMain } from './components/AppMain';
import { TagListSelector } from './components/TagListSelector';
import { CookieConsent } from './components/CookieConsent';






export class ENIAC {
    private DO: AppDrawer | null =  null;
  private topAppBarAppBar: AppBar | null = null; // Needs nested disposal.
  private Main: AppMain | null = null;
  private TagLists: TagListSelector[] = new Array<TagListSelector>();
  private SubMenus: DropDownLink[] = new Array<DropDownLink>();

    constructor() { }

    public Attach() {

      var a = document.querySelector<HTMLElement>('.mdc-drawer')
      if (a) this.DO = new AppDrawer(a);

      var b = document.querySelector('header');
      if (b && this.DO) this.topAppBarAppBar = new AppBar(b, this.DO);
      var c = document.getElementById('main-content');
      if (c && this.topAppBarAppBar) this.Main = new AppMain(c, this.topAppBarAppBar);


      var cc = document.querySelector<HTMLDivElement>('.cookieConsent');
      if (cc) var Cookies = new CookieConsent(cc);

        const Pagination = new PaginationController<HTMLElement>(document.querySelectorAll('[data-pagination]'));

      document.querySelectorAll<HTMLButtonElement>('.open-menu').forEach(el => this.SubMenus.push(new DropDownLink(el)));
      document.querySelectorAll<HTMLElement>('.mdc-chip-selector').forEach(el => {
        var s = new TagListSelector(el);
        s.Attach();
        this.TagLists.push(s);
      });

        //this.SubMenus = [].map.call(document.querySelectorAll<HTMLButtonElement>('.open-menu'), function (el) {
        //    return new DropDownLink(el);
        //});


        //this.TagLists = [].map.call(document.querySelectorAll<HTMLElement>(".mdc-chip-selector"), function (el) {
        //    var s = new TagListSelector(el);
        //    s.Attach();
        //    return s;
        //});



    }

    public Dispose() {
      if (this.DO) this.DO.Dispose();
      if (this.topAppBarAppBar)   this.topAppBarAppBar.Dispose();
      if (this.Main) this.Main.Dispose();
      if (this.TagLists) this.TagLists.forEach(o => o.Dispose);
      if (this.SubMenus) this.SubMenus.forEach(o => o.Dispose);
    }


}
