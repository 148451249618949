import { MDCMenu, Corner } from '@material/menu';


export class DropDownLink {
  private Menu: MDCMenu | undefined;

  static LastOpen: DropDownLink | null = null;

  private MenuID: string | undefined;
  private MenuElement: HTMLElement | null = null;
  private Previous: DropDownLink | null = null;

  constructor(private root: HTMLButtonElement) {
    if (root) {
      this.MenuID = root.dataset.submenuid;

      this.MenuElement = document.querySelector('#' + this.MenuID);

      if (this.MenuElement) {
        this.Menu = new MDCMenu(this.MenuElement);
        this.Menu.setAnchorCorner(Corner.BOTTOM_LEFT);
      }

      root.addEventListener('click', evt => this.onClick(evt));
    }
  }


  public Dispose() {
    if (this.Menu) this.Menu.destroy();
    this.root.removeEventListener('click', evt => this.onClick(evt));
  }

  private onClick(evt: MouseEvent): boolean {
    if (this.Menu) {
      if (!this.Menu.open) {
        if (DropDownLink.LastOpen) {
          if (DropDownLink.LastOpen.IsChild(this.root)) {
            this.Previous = DropDownLink.LastOpen;
          }
          else {
            DropDownLink.LastOpen.open = false;
          }
        }
        DropDownLink.LastOpen = this;
      }
      this.Menu.open = !this.Menu.open;
    }
       evt.preventDefault();
    evt.stopPropagation();
    evt.stopImmediatePropagation();

    return false;
  }


  get open(): boolean {
    if (!this.Menu) return false;
    return this.Menu.open;
  }
  set open(value: boolean) {
    if (this.Menu) {
      this.Menu.open = value;


      if (this.Previous) {
        this.Previous.open = value;
      }
    }
  }

    public IsChild(candidate: HTMLElement): boolean {

      if (this.root.hasChildNodes() && this.MenuElement) {
            return this.MenuElement.contains(candidate);
        }

        return false;
    }



}
