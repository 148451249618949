


import { CookieConsent } from './components/CookieConsent';


//import * as mdc from 'material-components-web';


//import autoInit from '@material/auto-init';
//import { MDCCheckbox } from '@material/checkbox';
//import { MDCChipSet } from '@material/chips/chip-set';
//import { MDCFormField } from '@material/form-field';
import { MDCList } from '@material/list';
//import { MDCSelect } from '@material/select';
//import { MDCTextField } from '@material/textfield';
//import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
//import { MDCRipple } from '@material/ripple';

//import * as base from '@material/base';
import { MDCCheckbox } from '@material/checkbox';
import { MDCChipSet } from '@material/chips';
//import * as circularProgress from '@material/circuclearlar-progress';
//import * as dataTable from '@material/data-table';
//import * as dialog from '@material/dialog';
//import * as dom from '@material/dom';
//import * as drawer from '@material/drawer';
//import * as floatingLabel from '@material/floating-label';
//import * as iconButton from '@material/icon-button';
//import * as lineRipple from '@material/line-ripple';
//import * as linearProgress from '@material/linear-progress';
import { MDCFormField } from '@material/form-field';
//import * as menuSurface from '@material/menu-surface';
//import {MDCMenu} from '@material/menu';
//import * as notchedOutline from '@material/notched-outline';
//import * as radio from '@material/radio';
//import * as ripple from '@material/ripple';
//import * as slider from '@material/slider';
//import * as snackbar from '@material/snackbar';
//import * as switchControl from '@material/switch';
//import * as tabBar from '@material/tab-bar';
//import * as tabIndicator from '@material/tab-indicator';
//import * as tabScroller from '@material/tab-scroller';
//import * as tab from '@material/tab';
import { MDCTextField } from '@material/textfield';
//import * as topAppBar from '@material/top-app-bar';
import { MDCSelect, MDCSelectAdapter, MDCSelectFoundation } from '@material/select';

//import { ckBalloon } from './components/ckBalloon';

import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
import { MDCRipple } from '@material/ripple';

import { FileUpload } from './components/FileUpload';
import { Columns } from './components/Columns';
import { ENIAC } from './ENIAC';
import { NotificationsWatcher } from './components/Notifications';

/*
// Register all components
autoInit.register('MDCCheckbox', checkbox.MDCCheckbox);
autoInit.register('MDCChip', chips.MDCChip);
autoInit.register('MDCChipSet', chips.MDCChipSet);
//y
autoInit.register('MDCCircularProgress', circularProgress.MDCCircularProgress);
autoInit.register('MDCDataTable', dataTable.MDCDataTable);
autoInit.register('MDCDialog', dialog.MDCDialog);
autoInit.register('MDCDrawer', drawer.MDCDrawer);
autoInit.register('MDCFloatingLabel', floatingLabel.MDCFloatingLabel);
autoInit.register('MDCFormField', formField.MDCFormField);
autoInit.register('MDCIconButtonToggle', iconButton.MDCIconButtonToggle);
autoInit.register('MDCLineRipple', lineRipple.MDCLineRipple);
autoInit.register('MDCLinearProgress', linearProgress.MDCLinearProgress);
autoInit.register('MDCList', list.MDCList);
autoInit.register('MDCMenu', menu.MDCMenu);
autoInit.register('MDCMenuSurface', menuSurface.MDCMenuSurface);
autoInit.register('MDCNotchedOutline', notchedOutline.MDCNotchedOutline);
autoInit.register('MDCRadio', radio.MDCRadio);
autoInit.register('MDCRipple', ripple.MDCRipple);
autoInit.register('MDCSelect', select.MDCSelect);
autoInit.register('MDCSlider', slider.MDCSlider);
autoInit.register('MDCSnackbar', snackbar.MDCSnackbar);
autoInit.register('MDCSwitch', switchControl.MDCSwitch);
autoInit.register('MDCTabBar', tabBar.MDCTabBar);
autoInit.register('MDCTextField', textField.MDCTextField);
autoInit.register('MDCTopAppBar', topAppBar.MDCTopAppBar);

//MDCSelect
//const MDC =  mdcAutoInit;


//console.log(MDC);
*/

    //autoInit.register('MDCTextField', MDCTextField);
    //autoInit.register('MDCFormField', MDCFormField);

const App: ENIAC = new ENIAC();


//function adminOpen(uri : string) {
//    var ret = window.open(uri, "_blank", "resizable=yes,width=600,height=500");

//}








console.log('app.ts');



document.addEventListener('DOMContentLoaded', () => {
  console.log('Init...');

  App.Attach();


  //  const tabBar = new     mdc.tabBar(document.querySelector('.mdc-tab-bar'));


  //const textField = new MDCTextField(document.querySelector('.mdc-text-field'));


  const textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), function (el) {
    return new MDCTextField(el);
  });


  const chipSetEl = [].map.call(document.querySelectorAll('.mdc-chip-set'), function (el) {
    return new MDCChipSet(el);
  });

  const listEl = [].map.call(document.querySelectorAll('.mdc-list'), function (el) {
    var o = new MDCList(el);

    const listItemRipples = o.listElements.map((listItemEl) => new MDCRipple(listItemEl));

    return o
  });

  

  const ProfileUploadTrigger = [].map.call(document.querySelectorAll('#profile-upload'), function (el) {
    return new FileUpload(el);

  });
  //console.log(ProfileUploadTrigger);

  var a = document.querySelector('.mdc-text-field-helper-text');
  if (a) { const helperText = new MDCTextFieldHelperText(a); }



  const formField = [].map.call(document.querySelectorAll('.mdc-form-field'), function (el: HTMLElement) {
    if (el) {
      var Field = new MDCFormField(el);

      //console.log('field', Field);

      var IsText = el.querySelector('.mdc-text-field');
      //console.log('text', IsText);
      if (IsText != null) {
        //  Field.input = new MDCTextField(IsText);
      }

      // var IsSelect = el.querySelector('.mdc-select');
      // if (IsSelect != null) {
      //     var Selecor = new MDCSelect(IsSelect); 
      //     Field.input = Selecor;
      //     var IField = document.getElementById(el.dataset.fieldId) as HTMLInputElement;

      //    if (IField) {
      //        Selecor.listen('MDCSelect:change', () => {
      //            //alert(`Selected option at index ${Selecor.selectedIndex} with value "${Selecor.value}"`);
      //             if (IField) {
      //                 IField.value = Selecor.value;
      //             }
      //          });
      //      }
      //   }











      var IsCheck = el.querySelector('.mdc-checkbox');
      if (IsCheck != null) {
        Field.input = new MDCCheckbox(IsCheck);
      }


      return Field;
    }
    return null;
  });



  const DialogTargetLinks = [].map.call(document.querySelectorAll('[data-dialogtarget]'), function (el: HTMLButtonElement) {
    el.onclick = s => {
      var ret = window.open(el.dataset.dialogtarget, "_blank", "resizable=yes,width=600,height=500");
    }
  });

  const SelectFields: MDCSelect[] = new Array<MDCSelect>();

  document.querySelectorAll<HTMLSelectElement>('.mdc-select').forEach(el => {

    //const SelectFields: MDCSelect[] = [].map.call(, function (el) {
    let Selecor = new MDCSelect(el);

    if (el.dataset.fieldId) {
      var IField = document.getElementById(el.dataset.fieldId) as HTMLInputElement;

      //console.log(Selecor);

      Selecor.listen('MDCSelect:change', () => {
        //alert(`Selected option at index ${Selecor.selectedIndex} with value "${Selecor.value}"`);
        if (IField) {
          IField.value = Selecor.value;
        }
      });

      SelectFields.push(Selecor);
    }
  });


 //   console.log(SelectFields[0]);

 //   class zzz implements MDCSelectAdapter {

  //  }

    //const adap = new MDCSelectAdaptor()
  //  const f: MDCSelectFoundation = new MDCSelectFoundation(SelectFields[0]);
    //f.init()
    //console.log(f);




    //const Editors =[].map.call(document.querySelectorAll('.editor.editor-balloon'), function (el) {
    //    return new ckBalloon(el);
    //});



    const FU = [].map.call(document.querySelectorAll('.FileUploadContainer'), function (el) {
        return new FileUpload(el);
    });

    const C = [].map.call(document.querySelectorAll('.blog-columns'), function (el) {
        return new Columns(el);
    });

    const Notifications = new NotificationsWatcher();
    //console.log(Notifications);

  const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action, A.block-link ';
  
  const buttonLinkRipples = [].map.call(document.querySelectorAll(selector), function (el) {
  
      return new MDCRipple(el);
  });
  

    //var cols = document.querySelectorAll('[cols]');
    //var articleList = document.querySelectorAll('[article]');


    //for (var i = 0; i < articleList.length; i++) {

    //    var Target = null;

    //    for (var col = 0; col < cols.length; col++) {
    //        if (Target == null) {
    //            Target = cols[col];
    //        } else {
    //            if (cols[col].clientHeight < Target.clientHeight) {
    //                Target = cols[col];
    //            }
    //        }
    //    }

    //    Target.appendChild(articleList[i])
    //}




    //menu.setFixedPosition(true);
   // menu.setAbsolutePosition(0, 10);


    //const MDC = mdcAutoInit();




   // const MDCInst = MDC.mdcAutoInit();

    //console.log(MDCInst);


  console.group('My LIst');
  var el = document.getElementById('my-list');
  console.log(el);
  if (el) {
    const MyList = new MDCList(el);
    MyList.singleSelection = true;


    console.log(MyList, MyList.listElements, MyList.selectedIndex);

    var onChangeListener = function (event) {
      console.log("List On Change",MyList.selectedIndex, event);
    }

    MyList.listen('MDCList:action', onChangeListener);
  }

  console.groupEnd();

});


  window.addEventListener('unload', function(event) {
      console.log('I am the 3rd one.');

      App.Dispose();

        //HasSubMenu[0].destroy();
      });
