import { MDCDrawer } from '@material/drawer';











export class AppDrawer {
    private _MDC: MDCDrawer | null = null;

    constructor(private root: HTMLElement) {
        if (root != null) {
            this._MDC = MDCDrawer.attachTo(root);


            // ESTORE FOCUS
          // https://material.io/components/navigation-drawer/web#using-navigation-drawers
  
            //const listEl = root.querySelector('.mdc-list');
            //if (listEl) {
            //  listEl.addEventListener('click', (event) => {
            //    if (this._MDC) {
            //      this._MDC.open = false;
            //    }
            //  });
            //}


        }
    }



    get Open(): boolean {
        if (this._MDC == null) return false;
        return this._MDC.open;
    }

    set Open(value: boolean) {
        if (this._MDC) {
            this._MDC.open = value
        }
    }


    public Dispose() {
      if (this._MDC) this._MDC.destroy();
    }


}
