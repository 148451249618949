import { Http } from '../Stuff/HttpRequest';



interface FileUploadModel {
  data: File;
  state: string;
  error: boolean;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  //sub?: Subscription;
}

interface IUploadResult {
    uploaded: boolean;
    url: string;
    id: string;
    filename: string;
}



export class FileUpload {
    public files: Array<FileUploadModel> = [];
    public Accept: HTMLButtonElement | null = null;
    public param = 'file';
  public target = '';
  public UploadPath: string | undefined | null = null;
  public UploadIDField: HTMLInputElement | null = null;
  public Image: HTMLImageElement | null = null;
    // FileUpload


    


    constructor(private root: HTMLDivElement) {
        if (this.root) {

            this.UploadPath = root.dataset.uploadPath;
            this.UploadIDField = root.querySelector<HTMLInputElement>('#' + root.dataset.uploadId);
            this.Image = root.querySelector<HTMLImageElement>("img");

            this.Accept = root.querySelector<HTMLButtonElement>("button");
            if (this.Accept) {
                this.Accept.onclick = (ev) => this.onClick(ev);
            }
        }
    }



    public onClick(evt: Event) {
        evt.preventDefault();
        evt.stopPropagation();
        evt.stopImmediatePropagation();
        evt.cancelBubble = true;

      const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;

      if (fileUpload) {
        fileUpload.onchange = () => {
          this.files = new Array<FileUploadModel>();

          if (fileUpload.files) {
            for (let index = 0; index < fileUpload.files.length; index++) {
              const file = fileUpload.files[index];
              var MetaData = {
                data: file,
                state: 'in',
                error: false,
                inProgress: false, progress: 0, canRetry: false, canCancel: true
              };

              this.files.push(MetaData);
              //console.log(this.files);
              this.uploadFile(MetaData);
            }
          }
        };
      }

        fileUpload.click();
    }

    cancelFile(file: FileUploadModel) {
        //file.sub.unsubscribe();
        this.removeFileFromArray(file);
    }

    retryFile(file: FileUploadModel) {
        this.uploadFile(file);
        file.canRetry = false;
    }

  private uploadFile(file: FileUploadModel) {
    const fd = new FormData();
    fd.append(this.param, file.data);


    //          let authHeaders = new HttpHeaders();//
    //          if (this.authenticationService.getAccessToken()) {
    //              authHeaders = authHeaders.append('Authorization', 'Bearer ' + this.authenticationService.getAccessToken());
    //          }



    //console.log(this.UploadPath);

    if (this.UploadPath) {
      const req = new Http<IUploadResult>(this.UploadPath);

      req.post(fd)
        .then(res => {
          //console.log(res);
          if (res != null && res.uploaded) {
            if (this.UploadIDField) this.UploadIDField.value = res.id;
            if (this.Image) {
              this.Image.src = res.url;
              this.Image.style.display = 'block';
            }
          }
        })
        .finally(() => { console.log('finished') });  // this.target

      file.inProgress = true;
      file.error = false;
      file.progress = 0;

    }
  }

    //private uploadFiles() {
    //  const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    //  fileUpload.value = '';

    //  this.files.forEach(file => {
    //    this.uploadFile(file);
    //  });
    //}

    private removeFileFromArray(file: FileUploadModel) {
        const index = this.files.indexOf(file);
        if (index > -1) {
            this.files.splice(index, 1);
        }
    }

}






