



//interface IPaging {
//    count: number;
//    rows : INotificationData[]
//}

//interface INotificationData {
//    id: string;
//    isRead: boolean;
//    messageType: number;
//    ref: string;
//    subject: string;
//}



interface IMessageInformation {
    count: number;

}



//class NotificationItem {
//    public id: string;
//    public isRead: boolean;
//    public messageType: number;
//    public ref: string;
//    public subject: string;
//    public elRef: HTMLLIElement;
//}







export class NotificationsWatcher {

  private Root: HTMLElement | null = null;
    private Icon: HTMLElement | null = null;
   // private ItemContainer: HTMLUListElement;
 //   private CurrentNotifications: NotificationItem[] = new Array<NotificationItem>();
  private TimerHandle: number | null = null; // Interval Handler
  private Info: IMessageInformation | null = null;

    constructor() {

        // /api/messaging/notifications

        this.Root = document.querySelector('#Notification-Bell');
        if (this.Root) {
            //this.Icon = this.Root.querySelector("i");
            this.Icon = this.Root.appendChild(document.createElement("span"));
            this.Icon.classList.add("Badge");

           // this.ItemContainer = this.Root.parentElement.querySelector('ul');

            this.TimerHandle = window.setInterval(() => {
                this.GetNotifications();
                this.UpdateNotificationIndicator();
            }, 5000);

        
            //this.ItemContainer.addEventListener("click", (event: MouseEvent) => {
            //    var source: HTMLButtonElement;

            //    if (event.srcElement instanceof HTMLButtonElement) {
            //        source = event.srcElement as HTMLButtonElement;
            //    } else {
            //        var Path = event.composedPath(); // event.path ||

            //        source = Path.find(e => e instanceof HTMLButtonElement) as HTMLButtonElement;
            //    }

            //        if (source) {
            //            var s = source.dataset.dialogtarget;
            //            if (s) {
            //                var ret = window.open(s, "_blank", "resizable=yes,width=600,height=500");
            //            }
            //        }

            //    });
        }
        // this.DataField = this.Root.querySelector<HTMLInputElement>('#' + this.Root.dataset.field);
    }



    public Dispose() {

        if (this.TimerHandle) {
            clearInterval(this.TimerHandle);
            this.TimerHandle = null;
        }
        //if (this.Timer) this.Timer.unref();
    }

    public FailRequests() {
        //if (this.Icon) {
        //    this.Icon.style.color = "#cc0000";
        //    this.Icon.style.fontWeight = 'bold';
        //}
        this.Dispose();
    }

    private GetNotifications() {
        var xmlhttp = new XMLHttpRequest();
        var Container = this;

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
                if (xmlhttp.status == 200) {
                    var Obj = JSON.parse(xmlhttp.responseText) as IMessageInformation;
               //     console.log(Obj);
                    Container.Info = Obj;
                    if (Obj) {

                        //var i = 0;
                        //while (i < Container.CurrentNotifications.length) {
                        //    var r = Container.CurrentNotifications[i];
                        //    if (Obj.rows.findIndex(o => o.id == r.id) == -1) {
                        //        Container.ItemContainer.removeChild(r.elRef);
                        //        Container.CurrentNotifications.splice(i, 1);
                        //    }
                        //    else {
                        //        i++;
                        //    }
                        //}


                        //Obj.rows.forEach(r => {

                        //    var Idx = Container.CurrentNotifications.findIndex(O => O.id == r.id);
                        //    if (Idx == -1) {
                        //        //    console.log("Exists");
                        //        //    Container.CurrentNotifications[Idx].subject = r.subject;
                        //        //    Container.CurrentNotifications[Idx].elRef.innerHTML = r.subject;
                        //        //} else {
                        //        var o = new NotificationItem();
                        //        o.id = r.id;
                        //        o.subject = r.subject;
                        //        o.isRead = r.isRead;
                        //        o.messageType = r.messageType;
                        //        o.elRef = document.createElement("li");
                        //        o.elRef.className = "mdc-list-item"


                        //        var Inner = o.elRef.appendChild<HTMLSpanElement>(document.createElement("span"))
                        //        Inner.className = "mdc-list-item__text";
                        //        var Button = Inner.appendChild<HTMLButtonElement>(document.createElement("button"))
                        //        Button.className = "mdc-button";
                        //        Button.dataset.dialogtarget = `/admin/messaging/${o.id}`;

                        //        var ButtonSpan = Button.appendChild<HTMLSpanElement>(document.createElement("span"))
                        //        ButtonSpan.className = "mdc-button__ripple";;
                        //        Button.appendChild(document.createTextNode(o.subject));


                        //        Container.ItemContainer.appendChild(o.elRef);
                        //        Container.CurrentNotifications.push(o);
                        //    }
                        //});


                    }

                    Container.UpdateNotificationIndicator();                    

                    //document.getElementById("myDiv").innerHTML = xmlhttp.responseText;
                }
                else if (xmlhttp.status == 400) {
                    Container.FailRequests();
                //    alert('There was an error 400');
                }
                else if (xmlhttp.status == 302) {
                  Container.FailRequests();
                  //    alert('There was an error 400');
                }
                else {
                    Container.FailRequests();
                //    alert('something else other than 200 was returned');
                }
            }
        };

        xmlhttp.open("GET", "/api/messaging/notifications", true);
        xmlhttp.send();
    }



    private UpdateNotificationIndicator() {
      if (this.Info && this.Icon) {
            this.Icon.innerText = this.Info.count.toString() || '';
   //         this.Icon.innerText = (this.CurrentNotifications.length > 0) ? "notifications" : "notifications_none";
        }
    }






}
