import { MDCMenu } from '@material/menu';
import { MDCChipSet } from '@material/chips';




interface SelectElement {
    ID: number;
    Name: string;
}




export class TagListSelector
{
    private SelectMenu: MDCMenu | null = null;
  private ChipSet: MDCChipSet | null = null;

  private InputField: HTMLInputElement | null = null;
  private ChipSetElement: HTMLDivElement | null = null;
  private DataField: HTMLInputElement | null = null;

    constructor(private Root : HTMLElement)
    {
        this.ChipSetElement = this.Root.querySelector('.mdc-chip-set');
       // this.DataField = this.Root.querySelector<HTMLInputElement>('#' + this.Root.dataset.field);
    }

    public Attach() {
      if (this.ChipSetElement) {
        this.ChipSet = new MDCChipSet(this.ChipSetElement);
        var a = this.Root.querySelector<HTMLElement>(".mdc-menu");
        if (a) this.SelectMenu = new MDCMenu(a);
        this.InputField = this.Root.querySelector<HTMLInputElement>("#chip-input");


        if (this.InputField) {
          this.InputField.onmouseup = ev => {
            this.Populate();
          };

          this.InputField.onfocus = ev => {
            //    this.Populate();
          };

          this.InputField.onkeydown = ev => { // key down, handle item select on return
            //console.log('onkeydown');

            switch (ev.keyCode) {
              case 13:
              case 188:
                //if (this.SelectMenu) this.SelectMenu.open = false;
                //break;
              case 92:  // menu key - cancel default.
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();

                if (this.InputField && this.InputField.value.length > 0) {
                  var NewChip = this.CreateChip(this.InputField.value);
                  if (this.ChipSetElement) this.ChipSetElement.appendChild(NewChip);
                  // this.ChipSet.addChip(NewChip);
                  this.InputField.value = '';
                  this.CaptureChips();
                }
                break;
              default:

            }
          };
          this.InputField.onkeyup = ev => { // key up - filter drop down list
            //console.log('onkeyup', ev.keyCode);
            switch (ev.keyCode) {
              case 13: // return
              case 27: // escape
              case 188: // comma
                if (this.SelectMenu) this.SelectMenu.open = false;
                break;
              case 93: // menu key - cancel default.
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();
                this.Populate();
                break;
              default:
                this.Populate();
            }
          };

          this.InputField.onkeypress = ev => { // terminate CR's
            //console.log('onkeypress', ev.keyCode);
            if (ev.keyCode == 13 || ev.keyCode == 93) {
              ev.preventDefault();
              ev.stopPropagation();
              ev.stopImmediatePropagation();
              return false;
            }
            return true;
          };


          if (this.SelectMenu) this.SelectMenu.listen('keydown', evt => {
            var Elm = evt.target as HTMLElement;
            if (Elm != null && evt.keyCode == 13) {
              //console.log(Elm);
              var NewChip = this.CreateChip(Elm.innerHTML);
              if (this.ChipSetElement) this.ChipSetElement.appendChild(NewChip);
              //  this.ChipSet.addChip(NewChip);
              if (this.InputField) this.InputField.value = '';
              this.CaptureChips();
            }
          });

          if (this.SelectMenu) this.SelectMenu.listen('click', evt => {
            var Elm = evt.target as HTMLElement;
            if (Elm != null) {
              //console.log(Elm);
              var NewChip = this.CreateChip(Elm.innerHTML);
              if (this.ChipSetElement) this.ChipSetElement.appendChild(NewChip);
              //this.ChipSet.addChip(NewChip);
              if (this.InputField) this.InputField.value = '';
              this.CaptureChips();
            }
          });

          this.ChipSet.listen("MDCChip:removal", evt => { this.CaptureChips() });

          this.CaptureChips();
        }
      }

    }

    public Dispose() {
        if (this.SelectMenu) this.SelectMenu.destroy();
        if (this.ChipSet) this.ChipSet.destroy();
    }


    private Populate() {
        //console.log('Populate');
        var List = this.Root.querySelector<HTMLElement>('.mdc-list');

    //  if (this.SelectMenu) this.SelectMenu.items.forEach(o => { if (List) List.removeChild(o) });

      if (this.Root.dataset.options) {
        var IC: SelectElement[] = eval(this.Root.dataset.options);
        IC.forEach(Candidate => {
          if (List && this.InputField) {
            if (Candidate.Name.toUpperCase().startsWith(this.InputField.value.toUpperCase())) {
              List.appendChild(this.CreateElement(Candidate));
            }
          }
        });
      }

      if (this.SelectMenu && this.SelectMenu.items.length > 0) {
            if (!this.SelectMenu.open) {
              var bodyRect = document.body.getBoundingClientRect();
              if (this.InputField) {
                var rect = this.InputField.getBoundingClientRect();
              }

                //var o = this.InputField as HTMLElement;
                //while (o != null) {
                //    console.log(o.getBoundingClientRect());
                //    o = o.parentElement;
                //}

                //console.log(rect.top , window.scrollY , bodyRect.top);
                //console.log(rect.top + window.scrollY);

                //console.log(bodyRect, rect, rect.bottom + bodyRect.top);
               // this.SelectMenu.setAbsolutePosition(rect.left, rect.bottom - bodyRect.height);
            //    this.SelectMenu.setFixedPosition(true);
                this.SelectMenu.open = true;
            }
        } else {
          if (this.SelectMenu) this.SelectMenu.open = false;
        }
    }


    private CreateElement(Elm: SelectElement): HTMLElement {

        var IDValuAttrib = document.createAttribute("data-value");
        IDValuAttrib.value = Elm.ID.toString();
        var TabIdxAttrib = document.createAttribute("tabindex");
        TabIdxAttrib.value = "0";


        var node = document.createElement("LI");                 // Create a <li> node
        node.classList.add("mdc-list-item");
        node.attributes.setNamedItem(IDValuAttrib);
        node.attributes.setNamedItem(TabIdxAttrib);
        
        var textnode = document.createTextNode(Elm.Name);         // Create a text node
        node.appendChild(textnode);                              // Append the text to <li>
        //List.appendChild(node);
        return node;
    }


    private CreateChip(chip : string) {
        var node = document.createElement("div");
        node.classList.add("mdc-chip");
        node.appendChild(document.createElement("div")).classList.add("mdc-chip__ripple");
           // ... perform operations to properly populate
        var GC = node.appendChild(document.createElement("span"));
        GC.className = "mdc-chip__primary-action";
        var IndexAttr = document.createAttribute("index");
        IndexAttr.value = "0";
        GC.attributes.setNamedItem(IndexAttr);

        var TextContainer = GC.appendChild(document.createElement("span"));
        TextContainer.className = "mdc-chip__text";

        var TF = TextContainer.appendChild(document.createElement("input"));
        TF.attributes.setNamedItem(this.ca("type", 'hidden'));
        TF.attributes.setNamedItem(this.ca("name", this.Root.dataset.field || ''));
        TF.attributes.setNamedItem(this.ca("value", chip));

        //<input type="text" name="@ID" value="@item" />

        var Tf2 = TextContainer.appendChild(document.createElement("span"));
        Tf2.className = "mdc-chip__text";

        Tf2.innerHTML = chip;

        var CCR = node.appendChild(document.createElement("span")).appendChild(document.createElement("i"));
        CCR.classList.add("material-icons","mdc-chip__icon","mdc-chip__icon--trailing");
        CCR.innerHTML = "cancel";


        return node;
    }

    private ca(name: string, value: string): Attr {
        var tmp = document.createAttribute(name);
        tmp.value = value;
        return tmp;
    }

    private CaptureChips() {
        //if (this.ChipSet.chips) {
        //    this.DataField.value = this.ChipSet.chips.map(c =>
        //        this.Root.querySelector("#" + c.id + " .mdc-chip__text").innerHTML
        //    ).join(",");
        //} else {
        //    this.DataField.value = '';
        //}
    }
}
