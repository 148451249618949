


export class CookieConsent {

    private Accept: HTMLButtonElement| null = null;

    constructor(public elm: HTMLDivElement) {
        if (this.elm) {
            this.Accept = elm.querySelector<HTMLButtonElement>("button[data-cookie-string]");
            if (this.Accept) {
                this.Accept.onclick = (ev) => this.AcceptAction();
            }
        }
    }



    public AcceptAction() {
        this.elm.classList.add('accepted');

        if (this.Accept) document.cookie = this.Accept.dataset.cookieString || '';
    }

}
