



const Gap : number = 32;


export class Columns {
    public TotalHeight : number = 0;
    public ColumnCount: number = 0;
    public CurrentWidth: number = 0;
    public Items: ColumnItem[] = new Array<ColumnItem>();

    private Resizing: boolean;
    private DeBounce: number = 5;
    private Timer: any; // Interval Handler


    constructor(private Root: HTMLElement) {
        Root.querySelectorAll<HTMLLIElement>('LI').forEach(i => this.Items.push(new ColumnItem(i)));

        this.Resizing = true;

        window.addEventListener('resize', (event: UIEvent) => {
            this.Resizing = true;
        }, { capture: false, passive: true });


        var Owner = this;
        

        this.Timer = setInterval(() => {
            var HasShown = false;

            this.Items.forEach(I => {
                if (!HasShown && !I.Visible) {
                    I.Visible = true;
                    HasShown = true;
                }
                if (HasShown) this.Layout();
            });

            if (Owner.Resizing) {
                if (Owner.DeBounce > 0) {
                    Owner.DeBounce--;
                    this.Layout();
                }
                else {
                    Owner.DeBounce = 5;
                    Owner.Resizing = false;
                }
            }
        }, 200);
    }




    public Layout() {
        this.CurrentWidth = this.Root.clientWidth;
        this.ColumnCount = 6;
        let AW = 0;

        while (AW < 300 && this.ColumnCount > 1) {
            this.ColumnCount--;
            AW = this.CalcWidthForColumns(this.ColumnCount);
        }

        let CurrentColumn = 0;
        let CurrentRow = 0;
        let MaxHeight = 0;
        let ColumnHeight: number[] = new Array<number>();

        this.Items.forEach(I => {
          //  I.Visible = true;
            I.Width = AW;
        });

        for (var i = 0; i < this.ColumnCount; i++) {
            ColumnHeight.push(0);
        }

        this.Items.forEach(I => {
            I.Position(Math.round(CurrentColumn * (AW + Gap)), Math.round(ColumnHeight[CurrentColumn] + (CurrentRow * Gap)));
            ColumnHeight[CurrentColumn] += I.Height;

            if (ColumnHeight[CurrentColumn] > MaxHeight) MaxHeight = ColumnHeight[CurrentColumn];

            CurrentColumn++;

            if (CurrentColumn >= this.ColumnCount) {
                CurrentColumn = 0;
                CurrentRow++;
            }
        });

        this.Root.style.height = Math.round((MaxHeight + (CurrentRow * Gap))) + 'px';
    }



    public CalcWidthForColumns(Columns: number): number {
        return (this.CurrentWidth - (Gap * (Columns - 1))) / Columns;
    }

}

class ColumnItem {

    
    constructor(private Root: HTMLElement) {
        
    }


    public Position(x: number, y: number) {
        this.Root.style.transform = `translate3d(${x}px,${y}px,0px)`;
    }

    get Height() { return this.Root.getBoundingClientRect().height; }

    set Width(value : number) { this.Root.style.width = value + 'px'; }

    get Visible() { return this.Root.style.display != 'none'; }
    set Visible(value) { this.Root.style.display = (value) ? 'block' : 'none'; }

}
