




export class PaginationController<T extends HTMLElement> {

  private _Controls: PaginationControl<T>[]  = new Array<PaginationControl<T>>();


    constructor(private root: NodeListOf<T>) {

        if (root) {
            root.forEach(T => {
                var Control = new PaginationControl<T>(T);
                this._Controls.push(Control)

                return Control;
            });
        }

    }




}



class PaginationControl<T extends HTMLElement> {
  private ModelID: string | undefined;
  private PrevButton: HTMLButtonElement | null;
  private NextButton: HTMLButtonElement | null;
  private Page: HTMLInputElement | null;
  private Form: HTMLFormElement | null;



  constructor(private pager: T) {
    this.ModelID = pager.dataset.pagination;

    this.PrevButton = pager.querySelector<HTMLButtonElement>('#' + this.ModelID + '_prev');
    this.NextButton = pager.querySelector<HTMLButtonElement>('#' + this.ModelID + '_next');
    this.Page = pager.querySelector<HTMLInputElement>('#' + this.ModelID + '_Page');
    this.Form = pager.closest('form');


    if (this.PrevButton) this.PrevButton.onclick = () => this.Prev();
    if (this.NextButton) this.NextButton.onclick = () => this.Next();
  }


  Prev() {
    if (this.Page) {
      let Value: number = parseInt(this.Page.value);

      this.Page.value = ((isNaN(Value)) ? 1 : (Value - 1)).toString();
      if (this.Form) this.Form.submit();
    }
  }


  Next() {
    if (this.Page) {
      let Value: number = parseInt(this.Page.value);

      this.Page.value = ((isNaN(Value)) ? 1 : (Value + 1)).toString();
      if (this.Form) this.Form.submit();
    }
  }

}
